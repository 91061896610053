import '../css/main.sass'

import '../images/app-store-badge.svg'
import '../images/background.png'
import '../images/google-play-badge.png'
import '../images/favicon.png'
import '../images/logo.svg'
import '../images/logo-with-text.svg'
import '../images/salah-diary-icon-144x144.png'
import '../images/salah-diary-icon-192x192.png'
